.Elect {
    &.modal-title {
        width: 100%;
        font-size: 32px;
    }

    &.body {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        justify-content: center;
        align-items: center;
    }

    &.img {
        width: 90%;
        object-fit: cover;
    }

    &.subtitle {
        margin: 20px;
        font-size: 20px;
    }

    &.description {
        white-space: pre-line;
    }

    &.modal-footer {
        justify-content: center;
        align-items: center;
    }
}