@import url('https://fonts.googleapis.com/css?family=Nunito');

body {
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  font-family: 'Nunito', sans-serif;
}

textarea {
  transition: none !important;
}

.ril__toolbar {
  background-color: transparent !important;

  button {
    outline: none;
  }
}

.scroll-touch {
  -webkit-overflow-scrolling: touch;
}

:root {
  // 50, 51, 91
  --theme-primary-color: #d32f2f;
  --theme-dark-color: #9a0007;
  // 50, 76, 221
  --theme-light-color: #ff6659;
}

.custom-btn {
  &.neutral {
    background-color: rgb(255, 255, 255);
  }

  &.primary {
    background-color: var(--theme-primary-color);
    border-color: var(--theme-primary-color);
    color: rgb(255, 255, 255);
  }
}

.xBold {
  font-weight: bolder;
}

.modal-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}