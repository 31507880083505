$timeout: 0.125s;

.confirm {
    @media screen and (max-width: 400px) {
        .imageWrapper {
            width: 100px !important;
            height: 100px !important;
        }
    }

    .fade-enter {
        opacity: 0.01;
        position: absolute;
        transform: translate(-50%, -50%);
    }
    
    .fade-enter.fade-enter-active {
        opacity: 1;
        transition: opacity $timeout ease-in;
        transition-delay: $timeout + 0.25s;
    }
    
    .fade-exit {
        opacity: 1;
    }
    
    .fade-exit.fade-exit-active {
        opacity: 0.01;
        transition: opacity $timeout ease-in;
    }
}