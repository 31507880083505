$timeout: 0.25s;
$transformDistance: 25px;

.loginTransition-enter {
    position: absolute;
    width: 100%;
    opacity: 0.01;
    transform: translateY(10px);
}
.loginTransition_login-enter {
    position: absolute;
    width: 100%;
    opacity: 0.01;

    .loginBox {
        transform: translateY($transformDistance);
    }
}

.loginTransition-enter.loginTransition-enter-active {
    transition: opacity $timeout ease-out, transform $timeout ease-out;
    transition-delay: $timeout + 0.2s;
    opacity: 1;
    transform: translateY(0);
}
.loginTransition_login-enter.loginTransition_login-enter-active {
    transition: opacity $timeout ease-out;
    transition-delay: $timeout + 0.2s;
    opacity: 1;

    .loginBox {
        transition: transform $timeout ease-out;
        transition-delay: $timeout + 0.2s;
        transform: translateY(0);
    }
}

.loginTransition-exit {
    opacity: 1;
}
.loginTransition_login-exit {
    opacity: 1;
    
    .loginBox {
        transform: translateY(0);
    }
}

.loginTransition-exit.loginTransition-exit-active {
    transition: opacity $timeout ease-in;
    opacity: 0.01;
}
.loginTransition_login-exit.loginTransition_login-exit-active {
    transition: opacity $timeout ease-in;
    opacity: 0.01;

    .loginBox {
        transition: transform $timeout ease-in;
        transform: translateY(-$transformDistance);
    }
}