@import "../../../../../styles/Elevation";

.panel {
    .electList {
        > .elect {
            $transition: 0.3s;
            
            @extend .elevation-1;
            max-height: 50px;
            background-color: #ffffff;
            transition: all $transition cubic-bezier(0.4, 0, 0.2, 1);

            .electHeader {
                transition: height $transition cubic-bezier(0.4, 0, 0.2, 1);
            }

            &.active {
                @extend .elevation-7;
                max-height: 675px;
                border-radius: 4px;

                $margin: 25px;
                $smallMargin: 15px;
                margin: $margin 0;

                &:first-child {
                    margin-top: $smallMargin;
                }

                &:last-child {
                    margin-bottom: $smallMargin;
                }

                .electHeader {
                    height: 75px !important;
                }
            }

            &:not(.active) {
                cursor: pointer;

                &:hover {
                    background-color: #fcfcfc;
                }

                &:not(:last-child) {
                    border-bottom: 0.5px solid #f7f7f7;
                }

                .deleteButton {
                    display: none !important;
                }

                .closeButton {
                    display: none !important;
                }
            }
        }
    }
}