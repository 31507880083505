.Break {
    &.root {
        width: 100%;
        padding: 25px 10px;
    }

    &.breakLineCol {
        align-self: center;
    }

    &.breakLine {
        display: flex;
        max-height: 0;
        font-size: 0;
        line-height: 0;
        clear: both;
        border: none;
        border-top: 1px solid var(--theme-primary-color);
        border-bottom: 1px solid var(--theme-primary-color);
    }

    &.text {
        text-align: center;

        h3 {
            color: var(--theme-primary-color);
            @media screen and (max-width: 576px) {
                font-size: 20px;
            }
        }
    }
}