.panel {
    .sidebar {
        transform: translateX(-300px);
        transition: transform 0.25s cubic-bezier(0.4, 0, 0.2, 1);

        &.open {
            transform: translateX(0);
        }

        .tabLabel {
            $marginLeft: 20px;

            margin-left: $marginLeft;
            width: calc(100% - #{$marginLeft});
            transition: background-color 0.25s, border-color 0.25s;
            color: #7c7c7c;
            border-right: 3px solid transparent;

            i {
                transform: translateX(0);
                transition: transform 0.25s ease;
            }

            &:hover {
                background-color: #f7f7f7;
            }

            &:active {
                background-color: #f2f2f2;
            }

            &.active {
                background-color: #e8f0fd;
                color: #4983f1 !important;
                border-color: #4983f1;
                font-weight: bold;

                i {
                    transform: translateX(7px);
                }
            }
        }
    }
}