.iconButton {
    background-color: rgba(0, 0, 0, 0);
    color: rgb(124, 124, 124);
    transition: background-color 0.25s;

    &.disabled {
        color: #b8b8b8;
        background-color: transparent;
    }

    &:not(.disabled) {
        cursor: pointer;

        &:hover {
            background-color: rgba(0, 0, 0, 0.05);
        }

        &:active {
            background-color: rgba(0, 0, 0, 0.1);
        }
    }
}