.Vote {
    &.container {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
    }

    &.data {
        width: 75%;
        margin-bottom: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &.img {
        margin-right: 16px;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        object-fit: cover;
    }
}