.Voting {
    &.root {
        max-width: 100%;
    }
    &.header {
        z-index: 100;
        position: fixed;
        width: 100vw;
        height: 60px;
        background-color: rgb(255, 255, 255);
    }

    &.parallaxContent {
        background-color: rgba(0, 0, 0, 0.2);
    }

    &.titleContainer {
        display: flex;
        width: 100%;
        height: 100vh;
        justify-content: center;
        align-items: center;
    }

    &.title {
        position: absolute;
        text-align: center;
        color: rgb(255, 255, 255);
        font-size: 69px;
        font-weight: 600;
        transform: translate(0, -100px);
        letter-spacing: 3px;
        word-spacing: 10px;

        @media screen and (max-width: 576px) {
            font-size: 55px;
        }
    }

    &.scrollIndicator {
        position: relative;
        color: rgb(255, 255, 255);
        align-self: flex-end;
        transition: bottom .3s ease-out;
        display: inline-block;
        bottom: 100px;
        cursor: pointer;

        &:hover {
            bottom: 110px;
        }
    }

    &.textContainer {
        z-index: 1;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        text-align: center;
        background-color: rgb(255, 255, 255);
        padding: 50px 25px;
        box-shadow: 0 0 10px 3px rgba(36, 36, 36, 0.2);
    }

    &.wrap {
        margin: 0;
        padding: 15px 12px;
        box-sizing: border-box;
        max-width: 1000px;
        text-align: justify;
        white-space: pre-line;

        > div:first-child {
            h3 {
                text-align: center;
                margin-bottom: 15px;

                @media screen and (max-width: 576px) {
                    font-size: 22px;
                }
            }

            span {
                hyphens: auto;
            }
        }

        img {
            width: 50%;

            @media only screen and (max-width: 768px) {
                max-width: 90%;
                width: 400px;
                margin: 75px 0;
            }
        }
    }

    &.viewContainer {
        position: relative;
        z-index: 1;
        justify-content: center;
        align-items: center;
        margin: 0;
        box-shadow: 0 0 10px 3px rgba(36, 36, 36, 0.2);
        padding: 20px 0 40px 0;
    }

    &.view {
        display: flex;
        flex-wrap: wrap;
        background-color: rgb(255, 255, 255);
    }

    &.profile {
        width: 100%;
        height: 100%;
    }

    &.noVote {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 100%;
        height: calc(100vh - (120px + 60px));
    }

    &.profilesContainerContainer {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &.profilesContainer {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        max-width: 1000px;
    }

    &.buttonContainer {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        padding: 15px 10px 15px 10px;
    }

    &.footerContainerContainer {
        position: relative;
        z-index: 2;
        display: flex;
        background-color: var(--theme-primary-color);
        width: 100%;
        min-height: 60px;
        justify-content: center;
        align-items: center;
        padding: 15px 0;
    }

    &.footerContainer {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        max-width: 1000px;
        justify-content: center;
        align-items: center;
    }

    &.footerImgContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 60px;
        padding: 5px;
    }

    &.footerImg {
        height: 50px;

        &:hover {
            opacity: 0.75;
        }

        @media screen and (max-width: 576px) {
            height: 40px;
        }
    }

    &.footerTextContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: 60px;
        padding: 5px;
        color: rgb(220, 224, 223);

        > a {
            color: rgb(220, 224, 223);

            &:hover {
                opacity: 0.75;
            }
        }
    }

    &.alertContainer {
        position: fixed;
        display: flex;
        bottom: 0;
        left: 0;
        justify-content: center;
        align-items: center;
        width: 100%;
        z-index: 1050;
    }

    &.alertFixed {
        width: 80%;
        text-align: center;
    }
}