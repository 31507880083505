.centeredLoader > div {
    animation: spin 1s ease;
    animation-iteration-count: infinite;
}

@keyframes spin {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(360deg);
    }
}