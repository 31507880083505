$timeout: 0.125s;

.panel .content {
    transition-property: margin-left, width, padding;
    transition-duration: 0.25s;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    
    margin-left: 0;
    width: 100%;
    padding: 20px 50px 50px 50px;
    -webkit-overflow-scrolling: touch;

    &.sidebarOpen {
        margin-left: 275px;
        width: calc(100% - 275px);
    }

    .toggleSidebarButton {
        display: none;
    }

    @media screen and (max-width: 750px) {
        padding: 20px 20px 50px 20px;

        .toggleSidebarButton {
            display: flex;
        }
    }

    .fade-enter {
        opacity: 0.01;
        position: absolute;
        width: 100%;
    }
    
    .fade-enter.fade-enter-active {
        opacity: 1;
        transition: opacity $timeout ease-in;
        transition-delay: $timeout;
    }
    
    .fade-exit {
        opacity: 1;
    }
    
    .fade-exit.fade-exit-active {
        opacity: 0.01;
        transition: opacity $timeout ease-in;
    }
}