.panel {
    .images {
        $rowHeight: 60px;

        th:first-child, td:first-child {
            width: 75px;
        }

        th:last-child, td:last-child {
            width: 50px;
        }

        .transitionGroup > div {
            &:not(:first-child) {
                height: $rowHeight;
            }

            div {
                display: inline-block;
            }

            div:first-child {
                width: 100px;
            }

            div:nth-child(2) {
                flex: 1;
            }

            div:last-child {
                width: 50px;
            }
        }

        $delay: 0.03;

        .scale-enter {
            opacity: 0.01;
            height: 0 !important;
            transform: translateX(-50px);
        }
        .scale-enter-active {
            opacity: 1;
            height: $rowHeight !important;
            transform: translateX(0);

            @for $i from 0 through 20 {
                &:nth-child(#{$i + 1}) {
                    transition: opacity 0.25s ease #{$i * $delay + 0.3}s, height 0.25s cubic-bezier(0.4, 0, 0.2, 1), transform 0.25s cubic-bezier(0, 0, 0.2, 1) #{$i * $delay + 0.25}s;
                }
            }
        }
        .scale-exit {
            opacity: 1;
            height: $rowHeight !important;
        }
        .scale-exit-active {
            opacity: 0.01;
            height: 0 !important;
            transform: translateX(50px);

            @for $i from 0 through 20 {
                &:nth-child(#{$i + 1}) {
                    transition: height 0.25s cubic-bezier(0.4, 0, 0, 1) #{$i * $delay + 0.25}s, opacity 0.25s ease, transform 0.25s cubic-bezier(0.4, 0, 0, 1);
                }
            }
        }
    }
}