$c1: rgba(0, 0, 0, 0.2);
$c2: rgba(0, 0, 0, 0.14);
$c3: rgba(0, 0, 0, 0.12);

.elevation-0 {box-shadow: none;}
.elevation-1 {box-shadow: 0px 1px 3px 0px $c1, 0px 1px 1px 0px $c2, 0px 2px 1px -1px $c3;}
.elevation-2 {box-shadow: 0px 1px 5px 0px $c1, 0px 2px 2px 0px $c2, 0px 3px 1px -2px $c3;}
.elevation-3 {box-shadow: 0px 1px 8px 0px $c1, 0px 3px 4px 0px $c2, 0px 3px 3px -2px $c3;}
.elevation-4 {box-shadow: 0px 2px 4px -1px $c1, 0px 4px 5px 0px $c2, 0px 1px 10px 0px $c3;}
.elevation-5 {box-shadow: 0px 3px 5px -1px $c1, 0px 5px 8px 0px $c2, 0px 1px 14px 0px $c3;}
.elevation-6 {box-shadow: 0px 3px 5px -1px $c1, 0px 6px 10px 0px $c2, 0px 1px 18px 0px $c3;}
.elevation-7 {box-shadow: 0px 4px 5px -2px $c1, 0px 7px 10px 1px $c2, 0px 2px 16px 1px $c3;}
.elevation-8 {box-shadow: 0px 5px 5px -3px $c1, 0px 8px 10px 1px $c2, 0px 3px 14px 2px $c3;}
.elevation-9 {box-shadow: 0px 5px 6px -3px $c1, 0px 9px 12px 1px $c2, 0px 3px 16px 2px $c3;}
.elevation-10 {box-shadow: 0px 6px 6px -3px $c1, 0px 10px 14px 1px $c2, 0px 4px 18px 3px $c3;}
.elevation-11 {box-shadow: 0px 6px 7px -4px $c1, 0px 11px 15px 1px $c2, 0px 4px 20px 3px $c3;}
.elevation-12 {box-shadow: 0px 7px 8px -4px $c1, 0px 12px 17px 2px $c2, 0px 5px 22px 4px $c3;}
.elevation-13 {box-shadow: 0px 7px 8px -4px $c1, 0px 13px 19px 2px $c2, 0px 5px 24px 4px $c3;}
.elevation-14 {box-shadow: 0px 7px 9px -4px $c1, 0px 14px 21px 2px $c2, 0px 5px 26px 4px $c3;}
.elevation-15 {box-shadow: 0px 8px 9px -5px $c1, 0px 15px 22px 2px $c2, 0px 6px 28px 5px $c3;}
.elevation-16 {box-shadow: 0px 8px 10px -5px $c1, 0px 16px 24px 2px $c2, 0px 6px 30px 5px $c3;}
.elevation-17 {box-shadow: 0px 8px 11px -5px $c1, 0px 17px 26px 2px $c2, 0px 6px 32px 5px $c3;}
.elevation-18 {box-shadow: 0px 9px 11px -5px $c1, 0px 18px 28px 2px $c2, 0px 7px 34px 6px $c3;}
.elevation-19 {box-shadow: 0px 9px 12px -6px $c1, 0px 19px 29px 2px $c2, 0px 7px 36px 6px $c3;}
.elevation-20 {box-shadow: 0px 10px 13px -6px $c1, 0px 20px 31px 3px $c2, 0px 8px 38px 7px $c3;}
.elevation-21 {box-shadow: 0px 10px 13px -6px $c1, 0px 21px 33px 3px $c2, 0px 8px 40px 7px $c3;}
.elevation-22 {box-shadow: 0px 10px 14px -6px $c1, 0px 22px 35px 3px $c2, 0px 8px 42px 7px $c3;}
.elevation-23 {box-shadow: 0px 11px 14px -7px $c1, 0px 23px 36px 3px $c2, 0px 9px 44px 8px $c3;}
.elevation-24 {box-shadow: 0px 11px 15px -7px $c1, 0px 24px 38px 3px $c2, 0px 9px 46px 8px $c3;}