@import "../../styles/Elevation.scss";

.Profile {
    transition-timing-function: linear;
    transition: background-color 0.25s, box-shadow 0.25s;

    &.root {
        display: flex;
        justify-content: center;
        height: 330px;
        padding: 15px 10px 15px 10px;
    }

    &.card {
        z-index: 10;
        width: 200px;
        height: 100%;
        border: 1px solid rgba(0, 0, 0, 0.08);
        cursor: pointer;

        &:hover[data-checked = false]:not(:active) {
            @extend .elevation-2;
        }

        &[data-checked = true] {
            @extend .elevation-4;
        }
    }

    &.card-img {
        z-index: 15;
        min-height: 75%;
        object-fit: cover;
    }

    &.card-body {
        z-index: 15;
        height: 100%;
        padding: 10px 15px;
    }

    &.card-title {
        margin-top: 5px;
        margin-bottom: 10px;
        font-size: 18px;
        font-weight: 500;
        color: #000000d9;
    }

    &.card-subtitle {
        line-height: 100%;
        font-size: 14px;
        color: #00000073;
    }

    &.radioContainer {
        z-index: 20;
        position: absolute;
        display: flex;
        width: 100%;
        justify-content: flex-end;
    }

    &.radio {
        position: relative;
        margin: 5px;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: rgba(255, 255, 255, 0.9);
        box-shadow: inset 0 0 4px 0 rgb(134, 134, 134);

        &:after {
            position: absolute;
            display:none;
            content: '';
            margin: 0;
        }

        &[data-checked=true] {
            background-color: var(--theme-primary-color);

            &:after {
                display: block;
                top: calc(50% - 4px);
                left: calc(50% - 4px);
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background-color: rgb(255, 255, 255);
            }
        }
    }
}
