.panel {
    .settings {
        .actionButtons {
            display: inline-block !important;
        }

        .window {
            transition: all 0.5s ease-in-out;
            overflow: hidden;
        }

        .window-exited, .window-entering {
            max-height: 0;
        }

        .window-entered, .window-exiting {
            max-height: 400px;
        }

        .windowVotingEnabled {
            transition: all 0.5s ease-in-out;
            overflow: hidden;

            span {
                display: block;
            }
        }

        .windowVotingEnabled-exited, .windowVotingEnabled-entering {
            max-height: 0;
        }

        .windowVotingEnabled-entered, .windowVotingEnabled-exiting {
            max-height: 400px;
        }
    }
}